// ----------------------------------------------------------------------------
// Header / Footer
// ----------------------------------------------------------------------------

=menu_nav
	margin: auto
	position: absolute
	top: 0
	left: 0
	right: 0
	max-width: $width-huge
	min-height: 90px
	z-index: 100
	@media only screen and (max-width : #{$width-huge})
		max-width: $width-big
	@media only screen and (max-width : #{$width-big})
		max-width: $width-medium
	@media only screen and (max-width : #{$width-medium})
		max-width: $width-regular
	.logo
		position: absolute
		top: 20px
		left: 0px
		z-index: 5
		@media only screen and (max-width : 1400px)
			left: 20px
		a
			+hide_text
			display: block
			background-image: url('#{$assetPath}/logo.svg')
			background-repeat: no-repeat
			background-size: 100%
			width: 200px
			height: 95px
	.hamburger
		display: none
	nav
		position: absolute
		top: 0
		right: 0
		left: 0
		.menu
			text-align: right
			margin-top: 40px
			+font_title
			@media only screen and (max-width : 1400px)
				padding-right: 20px
			li
				+reset
				position: relative
				display: inline-block
				margin-left: 35px
				@media only screen and (max-width : 1080px)
					margin-left: 20px
			&-item
				font-size: 1.5em
				font-weight: 100
				padding: 5px 0
				color: $color-basic-blue
				transition: all .3s ease-in-out
				border-bottom: 4px solid transparent
				text-decoration: none
				&.open, &.active, &:hover, &:focus
					border-bottom: 4px solid $color-basic-blue
				&.tooltip
					&:after, &:before
						position: absolute
						transition: all .2s ease-in-out
					&:after
						content: ''
						width: 10px
						height: 10px
						top: 25px
						left: 50%
						transform: translate(-50%) rotate(45deg)
						background: $color-basic-yellow
						z-index: 1
					&:before
						content: attr(data-tooltip)
						top: 30px
						left: 50%
						transform: translate(-50%)
						width: 100%
						padding: 5px
						font-size: .8em
						color: black
						text-align: center
						border-radius: 5px
						background: $color-basic-yellow
						z-index: 2
					&:hover
						&:after, &:before
							opacity: 0
						&:after
							top: 30px
						&:before
							top: 35px
			&-sub
				+reset
				position: absolute
				z-index: 1
				opacity: 0
				top: 30px
				left: -10px
				height: 0
				min-width: 140px
				transition: all .3s ease-in-out
				background: white
				overflow: hidden
				text-align: left
				padding: 10px 3px 3px 3px
				box-shadow: 0 20px 20px rgba(50,50,50,0.1)
				border-radius: 0 5px 5px 5px
				&:after
					content: ''
					display: block
					position: absolute
					left: 12px
					top: -6px
					width: 10px
					height: 10px
					background: $color-basic-blue
					transform: rotate(-45deg)
					z-index: -1
				&.open
					opacity: 1
					left: 0
					overflow: auto
					height: auto
				li
					+reset
					display: block
				&-item
					+reset
					font-size: 1.3em
					font-weight: 400
					padding: 10px
					color: $color-basic-blue
					transition: all .3s ease-in-out
					display: block
					&:hover, &:focus
						background: $color-basic-blue
						color: white

=menu_mobile
	box-sizing: border-box
	transition: all .5s ease
	position: fixed
	left: 0
	right: 0
	top: 0
	z-index: 1000
	height: 55px
	background: white
	box-shadow: 0 10px 10px rgba(0,0,0,0.1)
	.logo
		position: absolute
		top: 10px
		left: 10px
		z-index: 5
		a
			+hide_text
			display: block
			background-image: url('#{$assetPath}/logo.svg')
			background-repeat: no-repeat
			background-size: 100%
			width: 130px
			height: 45px
	.hamburger
		+hide_text
		position: absolute
		z-index: 100
		top: 11px
		right: 15px
		height: 48px
		width: 48px
		text-align: right
		font-size: 2em
		z-index: 10000
		color: $color-basic-blue
		text-decoration: none
		background-image: url('#{$assetPath}/icon_ui_menu.svg')
		background-repeat: no-repeat
		background-size: 30px
		background-position: center 5px
	&.open
		.hamburger
			background-image: url('#{$assetPath}/icon_ui_close.svg')
			background-size: 25px
	nav
		box-sizing: border-box
		transition: all .5s ease
		@include scrollable
		position: fixed
		top: 0
		bottom: 0
		right: -100%
		overflow: auto
		width: 100%
		height: 100%
		background: $color-basic-blue
		z-index: 1000
		box-shadow: -10px 0 10px rgba(0,0,0,0.1)
		padding: 50px
		opacity: 0
		@media only screen and (max-width : 400px)
			padding: 50px 20px
		&.open
			right: 0
			opacity: 1
		.logo
			+reset
			display: block
			width: 100%
			padding: 30px
		ul.menu
			+reset
			li
				+reset
				list-style: none
				a
					font-size: 3em
					padding: 20px
					display: block
					color: white
					@media only screen and (max-width : 500px)
						font-size: 2em
					@media only screen and (max-width : 400px)
						font-size: 1.8em
					&:hover
						background-color: darken($color-basic-blue, 20%)
						color: white


// ----------------------------------------------------------------------------
// Header
// ----------------------------------------------------------------------------

$strip-width: strip-unit( #{$width-small} )
$mobile-view: calc( #{$strip-width}  - 1px )

header.global
	@media only screen and (min-width : #{$width-small} )
		+menu_nav
	@media only screen and (max-width : #{$mobile-view} )
		+menu_mobile
		position: fixed
		&.goUp
			animation: goUp 1s normal forwards
		&.goDown
			animation: goDown 1s normal forwards


// ----------------------------------------------------------------------------
// Footer
// ----------------------------------------------------------------------------


footer.global
	position: relative
	background-color: black
	padding: 70px 15px 50px
	margin-top: 160px
	color: white
	@media only screen and (max-width : 800px)
		margin-top: 50px
	&:before
		content: ''
		position: absolute
		top: -139px
		left: calc( 50% - 100px )
		width: 200px
		height: 140px
		transform: rotate(180deg)
		@include shape_puzzle(black)
		@media only screen and (max-width : 800px)
			top: -69px
			left: calc( 50% - 50px )
			width: 100px
			height: 70px
	@media only screen and (max-width : 560px)
		.grid-item
			flex-basis: 100%
			width: 100%
			margin-bottom: 60px
			text-align: center
	h5
		+title_big
		display: block
		margin-bottom: 15px
	ul, p
		display: block
		+text_normal
		font-weight: 100
		margin-bottom: 30px
		a
			color: white
			text-decoration: underline
			transition: all .3s ease
			&:hover
				color: black
				background-color: white
	.grid-item
		text-align: center
	ul
		list-style: none
		line-height: 1.8em
		&.columns
			justify-content: center
			align-content: flex-start
			align-content: flex-start
			.clock
				flex-basis: 13%
				width: 13%
				padding-bottom: 13%
				text-align: left
			@media only screen and (max-width : 800px)
				.clock
					flex-basis: 20%
					width: 20%
					padding-bottom: 20%
			@media only screen and (max-width : 560px)
				.clock
					flex-basis: 25%
					width: 25%
					padding-bottom: 25%
					text-align: left
			@media only screen and (max-width : 420px)
				.clock
					flex-basis: 40%
					width: 40%
					padding-bottom: 40%
// ----------------------------------------------------------------------------
// Buttons
// ----------------------------------------------------------------------------

.btn
  +reset
  position: relative
  display: inline-block
  text-transform: uppercase
  text-decoration: none
  text-align: center
  transition: all .3s ease-in-out
  cursor: pointer
  line-height: 1em
  &-actions
    display: block
    width: 100%
    text-align: right
  &:hover
    box-shadow: 0 5px 5px rgba(0,0,0,0.2)
    transform: translateY(-2px)
  // Sizes
  &.big
    padding: 21px 40px 20px 40px
    font-size: 1.2em
    border-radius: $border-radius-md
  &.medium
    padding: 11px 25px 10px 25px
    font-size: 1.1em
    border-radius: $border-radius-sm
  &.regular
    padding: 11px 20px 10px 20px
    font-size: 1.2em
    border-radius: $border-radius-sm
  &.small
    padding: 8px 15px 7px 15px
    font-size: 1em
    border-radius: $border-radius-sm
  &.micro
    padding: 6px 10px 5px 10px
    font-size: .8em
    border-radius: $border-radius-sm
  // Colors 
  &.white
    background-color: white
    color: $color-basic-blue
  &.black
    background-color: black
    color: white
  &.grey
    background-color: #444
    color: white
  &.blue
    background-color: $color-basic-blue
    color: white
  &.cyan
    background-color: $color-basic-cyan
    color: white
  &.green
    background-color: $color-basic-green
    color: white
  &.purple
    background-color: $color-basic-purple
    color: white
  &.red
    background-color: $color-basic-red
    color: white
  &.orange
    background-color: $color-basic-orange
    color: white
.page
	background-color: white
	background-image: url('#{$assetPath}/textures/texture_shapes.svg')
	background-repeat: repeat
	background-size: 240px 240px
	background-attachment: fixed
	header.global
		.logo a
			background-image: url('#{$assetPath}/logo_white.svg')
		nav
			.menu-item
				color: white
				&.open, &.active, &:hover, &:focus
					border-bottom: 4px solid white
	footer.global
		margin-top: 250px
	main.global
		+reset
		display: block
		width: 100%
		.featured
			position: relative
			padding: 180px 15px 130px 15px
			margin-bottom: 100px
			background-color: $color-basic-blue
			background-image: url('#{$assetPath}/textures/texture_dots.svg')
			background-attachment: fixed
			background-repeat: repeat
			background-size: 900px
			background-position: center bottom
			&:after
				content: ''
				position: absolute
				bottom: 0
				left: calc( 70% - 50px )
				width: 100px
				height: 70px
				transform: rotate(180deg)
				@include shape_puzzle(white)
			article
				color: white
				text-align: center
				h2
					+title_huge
					line-height: 1.4em
				h3
					+title_big
					line-height: 1.4em
		.content
			+content_basic
		.box-solid
			position: relative
			padding: 80px 0
			margin: 120px auto
			background-color: $color-basic-blue
			background-image: url('#{$assetPath}/textures/texture_dots.svg')
			&:before
				content: ''
				position: absolute
				top: -69px
				left: calc( 30% - 50px )
				width: 100px
				height: 70px
				transform: rotate(180deg)
				@include shape_puzzle($color-basic-blue)
			&:after
				content: ''
				position: absolute
				bottom: -69px
				left: calc( 70% - 50px )
				width: 100px
				height: 70px
				@include shape_puzzle($color-basic-blue)
		.global-contact
			margin-top: 80px
			text-align: center
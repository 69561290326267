// ----------------------------------------------------------------------------
// Grid Elements
// ----------------------------------------------------------------------------

$column-space: 20px

.grid
	position: relative
	display: block
	box-sizing: border-box
	margin: auto
	width: 100%
	&.centered
		text-align: left
		@media only screen and (max-width : $width-huge)
			padding-left: 15px
			padding-right: 15px
	&.huge
		max-width: $width-huge
		@media only screen and (max-width : #{$width-huge})
			max-width: $width-big
		@media only screen and (max-width : #{$width-big})
			max-width: $width-medium
		@media only screen and (max-width : #{$width-medium})
			max-width: $width-regular
	&.big
		max-width: $width-big
		@media only screen and (max-width : #{$width-big})
			max-width: $width-medium
		@media only screen and (max-width : #{$width-medium})
			max-width: $width-regular
	&.medium
		max-width: $width-medium
		@media only screen and (max-width : #{$width-medium})
			max-width: $width-regular
	&.regular
		max-width: $width-regular
	&.small
		max-width: $width-small
	&.micro
		max-width: $width-micro
	&.columns
		display: flex
		flex-wrap: wrap
		justify-content: space-between
		align-items: stretch
		align-content: flex-start
	&-item
		flex-basis: 100%
		width: 100%
		box-sizing: border-box
		margin-bottom: $column-space
		// Columns build by number of elements
		&.col1
			flex-basis: 100%
			width: 100%
		&.col2
			flex-basis: calc( 50% - #{$column-space} )
			width: calc( 50% - #{$column-space} )
			@media only screen and (max-width : 640px)
				flex-basis: 100%
				width: 100%
		&.col3
			flex-basis: calc( (100% / 3) - #{$column-space} )
			width: calc( (100% / 3) - #{$column-space} )
			@media only screen and (max-width : 720px)
				flex-basis: calc( 50% - #{$column-space} )
				width: calc( 50% - #{$column-space} )
			@media only screen and (max-width : 520px)
				flex-basis: 100%
				width: 100%
		&.col4
			flex-basis: calc( 25% - #{$column-space} )
			width: calc( 25% - #{$column-space} )
			@media only screen and (max-width : 720px)
				flex-basis: calc( 50% - #{$column-space} )
				width: calc( 50% - #{$column-space} )
			@media only screen and (max-width : 520px)
				flex-basis: 100%
				width: 100%
		&.col5
			flex-basis: calc( (100% / 5) - #{$column-space} )
			width: calc( (100% / 5) - #{$column-space} )
			@media only screen and (max-width : 800px)
				flex-basis: calc( 25% - #{$column-space} )
				width: calc( 25% - #{$column-space} )
			@media only screen and (max-width : 720px)
				flex-basis: calc( (100% / 3) - #{$column-space} )
				width: calc( (100% / 3) - #{$column-space} )
			@media only screen and (max-width : 520px)
				flex-basis: calc( 50% - #{$column-space} )
				width: calc( 50% - #{$column-space} )
		// Columns build by %
		&.col66
			flex-basis: calc( ( ( 100% / 3) * 2 ) -  (#{$column-space} / 2) )
			width: calc( ( ( 100% / 3) * 2 ) -  (#{$column-space} / 2) )
			@media only screen and (max-width : 720px)
				flex-basis: calc( 50% - #{$column-space} )
				width: calc( 50% - #{$column-space} )
			@media only screen and (max-width : 520px)
				flex-basis: 100%
				width: 100%

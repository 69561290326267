@import url("https://fonts.googleapis.com/css?family=Fira+Sans:300,400,700|Montserrat:400,400i,700,700i");
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type=button], [type=reset], [type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type=button]:-moz-focusring, [type=reset]:-moz-focusring, [type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox], [type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

.clocks .clock {
  border-radius: 50%;
  background: radial-gradient(#000, #000 0.1em, #fff 0.1em, #fff), #fff;
  display: inline-block;
  margin: 1%;
  padding-bottom: 25%;
  margin-right: 20px;
  margin-bottom: 60px;
  position: relative;
  top: 50%;
  width: 25%;
  opacity: 1;
  transform: translateY(0%);
}
.clocks .clock::after {
  background: red;
  border-radius: 50%;
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 4%;
  height: 4%;
  z-index: 10;
}
.clocks .clock.station {
  background-size: 95%;
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.2) inset;
}
.clocks .clock.station .hours {
  background: #000;
  width: 3.5%;
  height: 40%;
  position: absolute;
  left: 48.25%;
  top: 22%;
  transform-origin: 50% 71%;
}
.clocks .clock.station .hours.angled {
  transform: rotateZ(-40deg);
}
.clocks .clock.station .minutes {
  background: #000;
  width: 3.5%;
  height: 55%;
  position: absolute;
  left: 48.25%;
  top: 7%;
  transform-origin: 50% 78.5%;
}
.clocks .clock.station .minutes.angled {
  transform: rotateZ(40deg);
}
.clocks .clock .label {
  position: absolute;
  bottom: -40px;
  width: 100%;
  text-align: center;
}
.clocks .clock .minutes-container, .clocks .clock .hours-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.clocks .clock .hours-container {
  animation: rotate 43200s infinite linear;
}
.clocks.bounce .minutes-container {
  transition: transform 0.3s cubic-bezier(0.4, 2.08, 0.55, 0.44);
}
.clocks .steps .minutes-container {
  animation: rotate 3600s infinite steps(60);
}
.clocks .local.steps .minutes-container {
  animation: none;
}
.clocks .clock.night {
  background: radial-gradient(#fff, #fff 0.1em, #444 0.1em, #444), #444;
}
.clocks .clock.night.station .hours, .clocks .clock.night.station .minutes {
  background: white;
}

@keyframes rotate {
  100% {
    transform: rotateZ(360deg);
  }
}
.animated {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}
@media only screen and (max-width: 700px) {
  .animated {
    animation: none !important;
  }
}
.animated.play {
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}
.animated.play.fadeIn {
  animation-name: fadeIn;
  animation-delay: 0.5s;
  animation-duration: 0.5s;
}
.animated.play.slideUp {
  animation-name: slideUp;
  animation-delay: 0.5s;
  animation-duration: 0.5s;
}
.animated.play.slideDown {
  animation-name: slideDown;
  animation-delay: 0.5s;
  animation-duration: 0.5s;
}
.animated.play.slideIn {
  animation-name: slideInRight;
  animation-delay: 0.5s;
  animation-duration: 0.5s;
}
.animated.play.slideIn:nth-of-type(1), .animated.play.slideIn:nth-of-type(2) {
  animation-name: slideInLeft;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes slideDown {
  from {
    transform: translate3d(0, -50px, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes slideUp {
  from {
    transform: translate3d(0, 50px, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes slideInLeft {
  from {
    transform: translate3d(-50px, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes slideInRight {
  from {
    transform: translate3d(50px, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes goUp {
  0% {
    opacity: 1;
    top: 0px;
  }
  100% {
    opacity: 0;
    top: -200px;
  }
}
@keyframes goDown {
  0% {
    opacity: 0;
    top: -200px;
  }
  100% {
    opacity: 1;
    top: 0px;
  }
}
@keyframes hover_horizontal_left {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes hover_horizontal_right {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes hovering {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-8px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-5px);
  }
}
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  text-decoration: none;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 76%;
  overflow-x: hidden;
  transition: all 0.5s ease;
  background-color: #F5F5F5;
}
body:hover {
  text-decoration: none;
}
body.disabled {
  position: absolute;
  right: 0;
  left: 0;
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6, p, ul, ol, a img {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  text-decoration: none;
}
h1:hover, h2:hover, h3:hover, h4:hover, h5:hover, h6:hover, p:hover, ul:hover, ol:hover, a img:hover {
  text-decoration: none;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: #5c57ff;
  text-decoration: none;
}

p, li {
  font-weight: 300;
}
p a, li a {
  color: #5c57ff;
  text-decoration: none;
}
p strong, li strong {
  font-weight: 900;
}

figure {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  text-decoration: none;
}
figure:hover {
  text-decoration: none;
}
figure img {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  text-decoration: none;
  display: block;
  width: 100%;
  height: auto;
}
figure img:hover {
  text-decoration: none;
}

blockquote {
  line-height: 1.8em;
  font-size: 1.6em;
  font-weight: 100;
  margin-bottom: 30px;
  padding: 0 0 0 20px;
}
blockquote small {
  display: block;
}
blockquote cite {
  display: block;
  font-size: 1rem;
  text-align: right;
}

.player {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  display: block;
}
.player.ratio1x1 {
  padding-bottom: 100%;
}
.player.ratio4x3 {
  padding-bottom: 75%;
}
.player.ratio3x2 {
  padding-bottom: 66.66%;
}
.player.ratio16x9 {
  padding-bottom: 56.25%;
}
.player img, .player iframe, .player div, .player video {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  text-decoration: none;
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.player img:hover, .player iframe:hover, .player div:hover, .player video:hover {
  text-decoration: none;
}

.grid {
  position: relative;
  display: block;
  box-sizing: border-box;
  margin: auto;
  width: 100%;
}
.grid.centered {
  text-align: left;
}
@media only screen and (max-width: 1440px) {
  .grid.centered {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.grid.huge {
  max-width: 1440px;
}
@media only screen and (max-width: 1440px) {
  .grid.huge {
    max-width: 1280px;
  }
}
@media only screen and (max-width: 1280px) {
  .grid.huge {
    max-width: 1024px;
  }
}
@media only screen and (max-width: 1024px) {
  .grid.huge {
    max-width: 960px;
  }
}
.grid.big {
  max-width: 1280px;
}
@media only screen and (max-width: 1280px) {
  .grid.big {
    max-width: 1024px;
  }
}
@media only screen and (max-width: 1024px) {
  .grid.big {
    max-width: 960px;
  }
}
.grid.medium {
  max-width: 1024px;
}
@media only screen and (max-width: 1024px) {
  .grid.medium {
    max-width: 960px;
  }
}
.grid.regular {
  max-width: 960px;
}
.grid.small {
  max-width: 720px;
}
.grid.micro {
  max-width: 480px;
}
.grid.columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-start;
}
.grid-item {
  flex-basis: 100%;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.grid-item.col1 {
  flex-basis: 100%;
  width: 100%;
}
.grid-item.col2 {
  flex-basis: calc(50% - 20px);
  width: calc(50% - 20px);
}
@media only screen and (max-width: 640px) {
  .grid-item.col2 {
    flex-basis: 100%;
    width: 100%;
  }
}
.grid-item.col3 {
  flex-basis: calc(33.3333333333% - 20px);
  width: calc(33.3333333333% - 20px);
}
@media only screen and (max-width: 720px) {
  .grid-item.col3 {
    flex-basis: calc(50% - 20px);
    width: calc(50% - 20px);
  }
}
@media only screen and (max-width: 520px) {
  .grid-item.col3 {
    flex-basis: 100%;
    width: 100%;
  }
}
.grid-item.col4 {
  flex-basis: calc(25% - 20px);
  width: calc(25% - 20px);
}
@media only screen and (max-width: 720px) {
  .grid-item.col4 {
    flex-basis: calc(50% - 20px);
    width: calc(50% - 20px);
  }
}
@media only screen and (max-width: 520px) {
  .grid-item.col4 {
    flex-basis: 100%;
    width: 100%;
  }
}
.grid-item.col5 {
  flex-basis: calc(20% - 20px);
  width: calc(20% - 20px);
}
@media only screen and (max-width: 800px) {
  .grid-item.col5 {
    flex-basis: calc(25% - 20px);
    width: calc(25% - 20px);
  }
}
@media only screen and (max-width: 720px) {
  .grid-item.col5 {
    flex-basis: calc(33.3333333333% - 20px);
    width: calc(33.3333333333% - 20px);
  }
}
@media only screen and (max-width: 520px) {
  .grid-item.col5 {
    flex-basis: calc(50% - 20px);
    width: calc(50% - 20px);
  }
}
.grid-item.col66 {
  flex-basis: calc(66.6666666667% - 20px / 2);
  width: calc(66.6666666667% - 20px / 2);
}
@media only screen and (max-width: 720px) {
  .grid-item.col66 {
    flex-basis: calc(50% - 20px);
    width: calc(50% - 20px);
  }
}
@media only screen and (max-width: 520px) {
  .grid-item.col66 {
    flex-basis: 100%;
    width: 100%;
  }
}

@media only screen and (min-width: 720px) {
  header.global {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    max-width: 1440px;
    min-height: 90px;
    z-index: 100;
  }
}
@media only screen and (min-width: 720px) and (max-width: 1440px) {
  header.global {
    max-width: 1280px;
  }
}
@media only screen and (min-width: 720px) and (max-width: 1280px) {
  header.global {
    max-width: 1024px;
  }
}
@media only screen and (min-width: 720px) and (max-width: 1024px) {
  header.global {
    max-width: 960px;
  }
}
@media only screen and (min-width: 720px) {
  header.global .logo {
    position: absolute;
    top: 20px;
    left: 0px;
    z-index: 5;
  }
}
@media only screen and (min-width: 720px) and (max-width: 1400px) {
  header.global .logo {
    left: 20px;
  }
}
@media only screen and (min-width: 720px) {
  header.global .logo a {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-indent: 100%;
    display: block;
    background-image: url("../img/logo.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    width: 200px;
    height: 95px;
  }
  header.global .hamburger {
    display: none;
  }
  header.global nav {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }
  header.global nav .menu {
    text-align: right;
    margin-top: 40px;
    font-family: "Fira Sans", Helvetica, Arial, sans-serif;
  }
}
@media only screen and (min-width: 720px) and (max-width: 1400px) {
  header.global nav .menu {
    padding-right: 20px;
  }
}
@media only screen and (min-width: 720px) {
  header.global nav .menu li {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    text-decoration: none;
    position: relative;
    display: inline-block;
    margin-left: 35px;
  }
  header.global nav .menu li:hover {
    text-decoration: none;
  }
}
@media only screen and (min-width: 720px) and (max-width: 1080px) {
  header.global nav .menu li {
    margin-left: 20px;
  }
}
@media only screen and (min-width: 720px) {
  header.global nav .menu-item {
    font-size: 1.5em;
    font-weight: 100;
    padding: 5px 0;
    color: #2C53BE;
    transition: all 0.3s ease-in-out;
    border-bottom: 4px solid transparent;
    text-decoration: none;
  }
  header.global nav .menu-item.open, header.global nav .menu-item.active, header.global nav .menu-item:hover, header.global nav .menu-item:focus {
    border-bottom: 4px solid #2C53BE;
  }
  header.global nav .menu-item.tooltip:after, header.global nav .menu-item.tooltip:before {
    position: absolute;
    transition: all 0.2s ease-in-out;
  }
  header.global nav .menu-item.tooltip:after {
    content: "";
    width: 10px;
    height: 10px;
    top: 25px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    background: #ffbd14;
    z-index: 1;
  }
  header.global nav .menu-item.tooltip:before {
    content: attr(data-tooltip);
    top: 30px;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    padding: 5px;
    font-size: 0.8em;
    color: black;
    text-align: center;
    border-radius: 5px;
    background: #ffbd14;
    z-index: 2;
  }
  header.global nav .menu-item.tooltip:hover:after, header.global nav .menu-item.tooltip:hover:before {
    opacity: 0;
  }
  header.global nav .menu-item.tooltip:hover:after {
    top: 30px;
  }
  header.global nav .menu-item.tooltip:hover:before {
    top: 35px;
  }
  header.global nav .menu-sub {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    text-decoration: none;
    position: absolute;
    z-index: 1;
    opacity: 0;
    top: 30px;
    left: -10px;
    height: 0;
    min-width: 140px;
    transition: all 0.3s ease-in-out;
    background: white;
    overflow: hidden;
    text-align: left;
    padding: 10px 3px 3px 3px;
    box-shadow: 0 20px 20px rgba(50, 50, 50, 0.1);
    border-radius: 0 5px 5px 5px;
  }
  header.global nav .menu-sub:hover {
    text-decoration: none;
  }
  header.global nav .menu-sub:after {
    content: "";
    display: block;
    position: absolute;
    left: 12px;
    top: -6px;
    width: 10px;
    height: 10px;
    background: #2C53BE;
    transform: rotate(-45deg);
    z-index: -1;
  }
  header.global nav .menu-sub.open {
    opacity: 1;
    left: 0;
    overflow: auto;
    height: auto;
  }
  header.global nav .menu-sub li {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    text-decoration: none;
    display: block;
  }
  header.global nav .menu-sub li:hover {
    text-decoration: none;
  }
  header.global nav .menu-sub-item {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    text-decoration: none;
    font-size: 1.3em;
    font-weight: 400;
    padding: 10px;
    color: #2C53BE;
    transition: all 0.3s ease-in-out;
    display: block;
  }
  header.global nav .menu-sub-item:hover {
    text-decoration: none;
  }
  header.global nav .menu-sub-item:hover, header.global nav .menu-sub-item:focus {
    background: #2C53BE;
    color: white;
  }
}
@media only screen and (max-width: calc(720px - 1px)) {
  header.global {
    box-sizing: border-box;
    transition: all 0.5s ease;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1000;
    height: 55px;
    background: white;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    position: fixed;
  }
  header.global .logo {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 5;
  }
  header.global .logo a {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-indent: 100%;
    display: block;
    background-image: url("../img/logo.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    width: 130px;
    height: 45px;
  }
  header.global .hamburger {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-indent: 100%;
    position: absolute;
    z-index: 100;
    top: 11px;
    right: 15px;
    height: 48px;
    width: 48px;
    text-align: right;
    font-size: 2em;
    z-index: 10000;
    color: #2C53BE;
    text-decoration: none;
    background-image: url("../img/icon_ui_menu.svg");
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: center 5px;
  }
  header.global.open .hamburger {
    background-image: url("../img/icon_ui_close.svg");
    background-size: 25px;
  }
  header.global nav {
    box-sizing: border-box;
    transition: all 0.5s ease;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    -moz-overflow-scrolling: touch;
    position: fixed;
    top: 0;
    bottom: 0;
    right: -100%;
    overflow: auto;
    width: 100%;
    height: 100%;
    background: #2C53BE;
    z-index: 1000;
    box-shadow: -10px 0 10px rgba(0, 0, 0, 0.1);
    padding: 50px;
    opacity: 0;
  }
}
@media only screen and (max-width: calc(720px - 1px)) and (max-width: 400px) {
  header.global nav {
    padding: 50px 20px;
  }
}
@media only screen and (max-width: calc(720px - 1px)) {
  header.global nav.open {
    right: 0;
    opacity: 1;
  }
  header.global nav .logo {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    text-decoration: none;
    display: block;
    width: 100%;
    padding: 30px;
  }
  header.global nav .logo:hover {
    text-decoration: none;
  }
  header.global nav ul.menu {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    text-decoration: none;
  }
  header.global nav ul.menu:hover {
    text-decoration: none;
  }
  header.global nav ul.menu li {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    text-decoration: none;
    list-style: none;
  }
  header.global nav ul.menu li:hover {
    text-decoration: none;
  }
  header.global nav ul.menu li a {
    font-size: 3em;
    padding: 20px;
    display: block;
    color: white;
  }
}
@media only screen and (max-width: calc(720px - 1px)) and (max-width: 500px) {
  header.global nav ul.menu li a {
    font-size: 2em;
  }
}
@media only screen and (max-width: calc(720px - 1px)) and (max-width: 400px) {
  header.global nav ul.menu li a {
    font-size: 1.8em;
  }
}
@media only screen and (max-width: calc(720px - 1px)) {
  header.global nav ul.menu li a:hover {
    background-color: #192f6b;
    color: white;
  }
  header.global.goUp {
    animation: goUp 1s normal forwards;
  }
  header.global.goDown {
    animation: goDown 1s normal forwards;
  }
}

footer.global {
  position: relative;
  background-color: black;
  padding: 70px 15px 50px;
  margin-top: 160px;
  color: white;
}
@media only screen and (max-width: 800px) {
  footer.global {
    margin-top: 50px;
  }
}
footer.global:before {
  content: "";
  position: absolute;
  top: -139px;
  left: calc(50% - 100px);
  width: 200px;
  height: 140px;
  transform: rotate(180deg);
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='140' viewBox='0 0 200 140'%3E%3Cpath fill='black' d='M161.777,38.552A38.391,38.391,0,0,1,200,0H0A38.391,38.391,0,0,1,38.223,38.552h.045V40a118.462,118.462,0,0,1-1.974,22.172,65.625,65.625,0,0,0-1.166,13.665C35.8,111.221,64.6,139.863,99.686,140c35.98.181,65.186-29.185,65.186-65.429a68.4,68.4,0,0,0-1.032-11.81,125.535,125.535,0,0,1-2.109-22.036V38.507h.045Z'/%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media only screen and (max-width: 800px) {
  footer.global:before {
    top: -69px;
    left: calc(50% - 50px);
    width: 100px;
    height: 70px;
  }
}
@media only screen and (max-width: 560px) {
  footer.global .grid-item {
    flex-basis: 100%;
    width: 100%;
    margin-bottom: 60px;
    text-align: center;
  }
}
footer.global h5 {
  display: inline-block;
  font-family: "Fira Sans", Helvetica, Arial, sans-serif;
  font-size: 1.8em;
  font-weight: 100;
  display: block;
  margin-bottom: 15px;
}
footer.global ul, footer.global p {
  display: block;
  display: inline-block;
  font-family: "Fira Sans", Helvetica, Arial, sans-serif;
  font-size: 1.4em;
  line-height: 1.4em;
  font-weight: 300;
  font-weight: 100;
  margin-bottom: 30px;
}
footer.global ul a, footer.global p a {
  color: white;
  text-decoration: underline;
  transition: all 0.3s ease;
}
footer.global ul a:hover, footer.global p a:hover {
  color: black;
  background-color: white;
}
footer.global .grid-item {
  text-align: center;
}
footer.global ul {
  list-style: none;
  line-height: 1.8em;
}
footer.global ul.columns {
  justify-content: center;
  align-content: flex-start;
  align-content: flex-start;
}
footer.global ul.columns .clock {
  flex-basis: 13%;
  width: 13%;
  padding-bottom: 13%;
  text-align: left;
}
@media only screen and (max-width: 800px) {
  footer.global ul.columns .clock {
    flex-basis: 20%;
    width: 20%;
    padding-bottom: 20%;
  }
}
@media only screen and (max-width: 560px) {
  footer.global ul.columns .clock {
    flex-basis: 25%;
    width: 25%;
    padding-bottom: 25%;
    text-align: left;
  }
}
@media only screen and (max-width: 420px) {
  footer.global ul.columns .clock {
    flex-basis: 40%;
    width: 40%;
    padding-bottom: 40%;
  }
}

.btn {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  text-decoration: none;
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  line-height: 1em;
}
.btn:hover {
  text-decoration: none;
}
.btn-actions {
  display: block;
  width: 100%;
  text-align: right;
}
.btn:hover {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}
.btn.big {
  padding: 21px 40px 20px 40px;
  font-size: 1.2em;
  border-radius: 7px;
}
.btn.medium {
  padding: 11px 25px 10px 25px;
  font-size: 1.1em;
  border-radius: 5px;
}
.btn.regular {
  padding: 11px 20px 10px 20px;
  font-size: 1.2em;
  border-radius: 5px;
}
.btn.small {
  padding: 8px 15px 7px 15px;
  font-size: 1em;
  border-radius: 5px;
}
.btn.micro {
  padding: 6px 10px 5px 10px;
  font-size: 0.8em;
  border-radius: 5px;
}
.btn.white {
  background-color: white;
  color: #2C53BE;
}
.btn.black {
  background-color: black;
  color: white;
}
.btn.grey {
  background-color: #444;
  color: white;
}
.btn.blue {
  background-color: #2C53BE;
  color: white;
}
.btn.cyan {
  background-color: #47d8aa;
  color: white;
}
.btn.green {
  background-color: #31aa13;
  color: white;
}
.btn.purple {
  background-color: #ab61ff;
  color: white;
}
.btn.red {
  background-color: #D90000;
  color: white;
}
.btn.orange {
  background-color: #ff7700;
  color: white;
}

.page {
  background-color: white;
  background-image: url("../img/textures/texture_shapes.svg");
  background-repeat: repeat;
  background-size: 240px 240px;
  background-attachment: fixed;
}
.page header.global .logo a {
  background-image: url("../img/logo_white.svg");
}
.page header.global nav .menu-item {
  color: white;
}
.page header.global nav .menu-item.open, .page header.global nav .menu-item.active, .page header.global nav .menu-item:hover, .page header.global nav .menu-item:focus {
  border-bottom: 4px solid white;
}
.page footer.global {
  margin-top: 250px;
}
.page main.global {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  text-decoration: none;
  display: block;
  width: 100%;
}
.page main.global:hover {
  text-decoration: none;
}
.page main.global .featured {
  position: relative;
  padding: 180px 15px 130px 15px;
  margin-bottom: 100px;
  background-color: #2C53BE;
  background-image: url("../img/textures/texture_dots.svg");
  background-attachment: fixed;
  background-repeat: repeat;
  background-size: 900px;
  background-position: center bottom;
}
.page main.global .featured:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: calc(70% - 50px);
  width: 100px;
  height: 70px;
  transform: rotate(180deg);
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='140' viewBox='0 0 200 140'%3E%3Cpath fill='white' d='M161.777,38.552A38.391,38.391,0,0,1,200,0H0A38.391,38.391,0,0,1,38.223,38.552h.045V40a118.462,118.462,0,0,1-1.974,22.172,65.625,65.625,0,0,0-1.166,13.665C35.8,111.221,64.6,139.863,99.686,140c35.98.181,65.186-29.185,65.186-65.429a68.4,68.4,0,0,0-1.032-11.81,125.535,125.535,0,0,1-2.109-22.036V38.507h.045Z'/%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.page main.global .featured article {
  color: white;
  text-align: center;
}
.page main.global .featured article h2 {
  display: inline-block;
  font-family: "Fira Sans", Helvetica, Arial, sans-serif;
  font-size: 3em;
  font-weight: 100;
  width: 100%;
  line-height: 1.4em;
}
@media only screen and (max-width: 760px) {
  .page main.global .featured article h2 {
    font-size: 2.5em;
  }
}
.page main.global .featured article h2 strong {
  font-weight: 100;
  border-bottom: 2px solid #D90000;
}
.page main.global .featured article h3 {
  display: inline-block;
  font-family: "Fira Sans", Helvetica, Arial, sans-serif;
  font-size: 1.8em;
  font-weight: 100;
  line-height: 1.4em;
}
.page main.global .content h2, .page main.global .content h3, .page main.global .content h4, .page main.global .content h5 {
  font-weight: 100;
  font-family: "Fira Sans", Helvetica, Arial, sans-serif;
}
.page main.global .content h3 {
  font-size: 2.8em;
  margin-top: 60px;
  margin-bottom: 30px;
}
.page main.global .content h3:first-of-type {
  margin-top: 0;
}
.page main.global .content h4 {
  font-family: "Fira Sans", Helvetica, Arial, sans-serif;
  font-size: 2.2em;
  margin-bottom: 20px;
}
.page main.global .content h5 {
  font-family: "Fira Sans", Helvetica, Arial, sans-serif;
  font-size: 2em;
  margin-bottom: 20px;
}
.page main.global .content p, .page main.global .content blockquote {
  font-size: 1.3em;
  line-height: 1.8em;
  margin-bottom: 20px;
}
.page main.global .content blockquote {
  padding-left: 30px;
  font-size: 1.4em;
  font-style: italic;
  font-weight: 100;
}
@media only screen and (max-width: 480px) {
  .page main.global .content blockquote {
    padding-left: 0px;
  }
}
.page main.global .content ul, .page main.global .content ol {
  margin-left: 20px;
  margin-bottom: 20px;
}
.page main.global .content ul li, .page main.global .content ol li {
  font-size: 1.2em;
  line-height: 1.8em;
}
.page main.global .content .player {
  margin-bottom: 30px;
}
.page main.global .content figure {
  text-align: center;
  width: 100%;
  margin: 60px auto;
}
.page main.global .content figure img {
  display: block;
  width: 100%;
  height: auto;
}
.page main.global .content figure.left {
  width: 35%;
  max-width: 320px;
  margin: 30px 60px 30px;
  float: left;
}
@media only screen and (max-width: 480px) {
  .page main.global .content figure.left {
    float: none;
    width: 100%;
    margin: 40px auto;
  }
}
.page main.global .content figure.right {
  width: 35%;
  max-width: 320px;
  margin: 30px 30px 30px 60px;
  float: right;
}
@media only screen and (max-width: 480px) {
  .page main.global .content figure.right {
    float: none;
    width: 100%;
    margin: 40px auto;
  }
}
.page main.global .box-solid {
  position: relative;
  padding: 80px 0;
  margin: 120px auto;
  background-color: #2C53BE;
  background-image: url("../img/textures/texture_dots.svg");
}
.page main.global .box-solid:before {
  content: "";
  position: absolute;
  top: -69px;
  left: calc(30% - 50px);
  width: 100px;
  height: 70px;
  transform: rotate(180deg);
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='140' viewBox='0 0 200 140'%3E%3Cpath fill='%232C53BE' d='M161.777,38.552A38.391,38.391,0,0,1,200,0H0A38.391,38.391,0,0,1,38.223,38.552h.045V40a118.462,118.462,0,0,1-1.974,22.172,65.625,65.625,0,0,0-1.166,13.665C35.8,111.221,64.6,139.863,99.686,140c35.98.181,65.186-29.185,65.186-65.429a68.4,68.4,0,0,0-1.032-11.81,125.535,125.535,0,0,1-2.109-22.036V38.507h.045Z'/%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.page main.global .box-solid:after {
  content: "";
  position: absolute;
  bottom: -69px;
  left: calc(70% - 50px);
  width: 100px;
  height: 70px;
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='140' viewBox='0 0 200 140'%3E%3Cpath fill='%232C53BE' d='M161.777,38.552A38.391,38.391,0,0,1,200,0H0A38.391,38.391,0,0,1,38.223,38.552h.045V40a118.462,118.462,0,0,1-1.974,22.172,65.625,65.625,0,0,0-1.166,13.665C35.8,111.221,64.6,139.863,99.686,140c35.98.181,65.186-29.185,65.186-65.429a68.4,68.4,0,0,0-1.032-11.81,125.535,125.535,0,0,1-2.109-22.036V38.507h.045Z'/%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.page main.global .global-contact {
  margin-top: 80px;
  text-align: center;
}

.home main.global {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  text-decoration: none;
  display: block;
  width: 100%;
}
.home main.global:hover {
  text-decoration: none;
}
.home main.global .featured {
  position: relative;
  padding: 200px 15px 50px 15px;
  background-color: white;
  text-align: center;
}
@media only screen and (max-width: 720px) {
  .home main.global .featured {
    padding-top: 120px;
  }
}
.home main.global .featured:after {
  content: "";
  position: absolute;
  bottom: -139px;
  left: calc(30% - 100px);
  width: 200px;
  height: 140px;
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='140' viewBox='0 0 200 140'%3E%3Cpath fill='white' d='M161.777,38.552A38.391,38.391,0,0,1,200,0H0A38.391,38.391,0,0,1,38.223,38.552h.045V40a118.462,118.462,0,0,1-1.974,22.172,65.625,65.625,0,0,0-1.166,13.665C35.8,111.221,64.6,139.863,99.686,140c35.98.181,65.186-29.185,65.186-65.429a68.4,68.4,0,0,0-1.032-11.81,125.535,125.535,0,0,1-2.109-22.036V38.507h.045Z'/%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media only screen and (max-width: 1024px) {
  .home main.global .featured:after {
    left: calc(20% - 50px);
  }
}
@media only screen and (max-width: 800px) {
  .home main.global .featured:after {
    bottom: -69px;
    left: calc(30% - 50px);
    width: 100px;
    height: 70px;
  }
}
.home main.global .featured article {
  max-width: 720px;
  margin: auto;
  padding-bottom: 40px;
  text-align: center;
}
@media only screen and (max-width: 640px) {
  .home main.global .featured article {
    margin-bottom: 60px;
  }
}
.home main.global .featured article h2 {
  display: inline-block;
  font-family: "Fira Sans", Helvetica, Arial, sans-serif;
  font-size: 3em;
  font-weight: 100;
  width: 100%;
  margin-bottom: 60px;
}
@media only screen and (max-width: 760px) {
  .home main.global .featured article h2 {
    font-size: 2.5em;
  }
}
.home main.global .featured article h2 strong {
  font-weight: 100;
  border-bottom: 2px solid #D90000;
}
@media only screen and (max-width: 640px) {
  .home main.global .featured article h2 {
    margin-bottom: 30px;
  }
}
.home main.global .featured article p {
  display: inline-block;
  font-family: "Fira Sans", Helvetica, Arial, sans-serif;
  font-size: 1.8em;
  font-weight: 100;
}
.home main.global .featured aside {
  padding-bottom: 30%;
  position: relative;
  transform: perspective(800px) rotateY(-5deg);
  transition: transform 1s ease-in-out;
}
@media only screen and (max-width: 640px) {
  .home main.global .featured aside {
    padding-bottom: 60%;
  }
}
.home main.global .featured aside:hover {
  transform: perspective(500px) rotateY(-5deg);
}
.home main.global .featured aside:hover figure.fig_1 {
  transform: translate(45px, -60px);
}
.home main.global .featured aside:hover figure.fig_2 {
  transform: scale(0.95) translate(5px, -30px);
}
.home main.global .featured aside figure {
  position: absolute;
  display: block;
  left: 0;
  top: 60px;
  right: 60px;
  bottom: 0;
  transition: transform 1s ease-in-out;
}
.home main.global .featured aside figure.fig_1 {
  transform: translate(30px, -60px);
}
.home main.global .featured aside figure.fig_2 {
  transform: scale(0.95) translate(0px, -30px);
}
.home main.global .featured aside figure.fig_3 {
  transform: scale(0.9) translate(-30px, 0);
}
.home main.global .tools {
  padding: 100px 0 250px 0;
  background-image: url("../img/textures/texture_shapes.svg");
  background-repeat: repeat;
  background-size: 240px 240px;
}
@media only screen and (max-width: 800px) {
  .home main.global .tools {
    padding-bottom: 100px;
  }
}
.home main.global .tools header {
  display: block;
  text-align: right;
  margin-bottom: 60px;
}
@media only screen and (max-width: 540px) {
  .home main.global .tools header {
    text-align: left;
  }
}
.home main.global .tools header h3 {
  display: inline-block;
  font-family: "Fira Sans", Helvetica, Arial, sans-serif;
  font-size: 3em;
  font-weight: 100;
  width: 100%;
  margin-bottom: 15px;
}
@media only screen and (max-width: 760px) {
  .home main.global .tools header h3 {
    font-size: 2.5em;
  }
}
.home main.global .tools header h3 strong {
  font-weight: 100;
  border-bottom: 2px solid #D90000;
}
.home main.global .tools header p {
  display: inline-block;
  font-family: "Fira Sans", Helvetica, Arial, sans-serif;
  font-size: 1.6em;
  line-height: 1.4em;
  font-weight: 300;
  max-width: 550px;
}
.home main.global .tools a {
  color: black;
}
.home main.global .tools article {
  display: block;
  padding: 20px 20px 40px 20px;
  background: white;
  border-radius: 3px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}
.home main.global .tools article:hover {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
  transform: translate3d(0, -5px, 0);
  transform-style: flat;
  text-decoration: none;
}
.home main.global .tools article h4 {
  display: inline-block;
  font-family: "Fira Sans", Helvetica, Arial, sans-serif;
  font-size: 1.8em;
  font-weight: 100;
  margin-bottom: 20px;
}
.home main.global .tools article p {
  display: inline-block;
  font-family: "Fira Sans", Helvetica, Arial, sans-serif;
  font-size: 1.4em;
  line-height: 1.4em;
  font-weight: 300;
}
.home main.global .tools article.cat-fundamentals {
  border-bottom: 3px solid #00bee5;
}
.home main.global .tools article.cat-events {
  border-bottom: 3px solid #ffbd14;
}
.home main.global .tools article.cat-grants {
  border-bottom: 3px solid #31aa13;
}
.home main.global .tools article.cat-cases {
  border-bottom: 3px solid #ff7700;
}
.home main.global .tools article.cat-learning {
  border-bottom: 3px solid #ab61ff;
}
.home main.global .method h3 {
  display: inline-block;
  font-family: "Fira Sans", Helvetica, Arial, sans-serif;
  font-size: 3em;
  font-weight: 100;
  width: 100%;
  display: block;
  margin-bottom: 20px;
}
@media only screen and (max-width: 760px) {
  .home main.global .method h3 {
    font-size: 2.5em;
  }
}
.home main.global .method h3 strong {
  font-weight: 100;
  border-bottom: 2px solid #D90000;
}
.home main.global .method h4 {
  display: inline-block;
  font-family: "Fira Sans", Helvetica, Arial, sans-serif;
  font-size: 1.8em;
  font-weight: 100;
  display: block;
  margin-bottom: 20px;
}
.home main.global .method p {
  display: inline-block;
  font-family: "Fira Sans", Helvetica, Arial, sans-serif;
  font-size: 1.4em;
  line-height: 1.4em;
  font-weight: 300;
  display: block;
  margin-bottom: 20px;
}
.home main.global .method .box {
  position: relative;
  padding: 100px 30px;
}
@media only screen and (max-width: 480px) {
  .home main.global .method .box {
    padding-left: 0;
    padding-right: 0;
  }
}
.home main.global .method .box article .info, .home main.global .method .box article .btn {
  margin-left: 20px;
}
.home main.global .method .box .grid.columns {
  align-items: middle;
  align-content: center;
}
.home main.global .method .box .grid.columns .grid-item {
  align-self: center;
}
.home main.global .method .box .grid.rightToLeft {
  flex-direction: row-reverse;
}
.home main.global .method .box figure img {
  display: block;
  margin: auto;
  width: auto;
  height: 100%;
  max-height: 350px;
}
.home main.global .method .method_01 {
  flex-basis: 100%;
  width: 100%;
  background-color: #2C53BE;
  background-image: url("../img/textures/texture_dots.svg");
  background-repeat: repeat;
  background-size: 900px;
  background-position: center bottom;
  color: white;
}
.home main.global .method .method_01:before {
  content: "";
  position: absolute;
  top: -139px;
  right: calc(30% - 100px);
  width: 200px;
  height: 140px;
  transform: rotate(180deg);
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='140' viewBox='0 0 200 140'%3E%3Cpath fill='%232C53BE' d='M161.777,38.552A38.391,38.391,0,0,1,200,0H0A38.391,38.391,0,0,1,38.223,38.552h.045V40a118.462,118.462,0,0,1-1.974,22.172,65.625,65.625,0,0,0-1.166,13.665C35.8,111.221,64.6,139.863,99.686,140c35.98.181,65.186-29.185,65.186-65.429a68.4,68.4,0,0,0-1.032-11.81,125.535,125.535,0,0,1-2.109-22.036V38.507h.045Z'/%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media only screen and (max-width: 800px) {
  .home main.global .method .method_01:before {
    top: -69px;
    right: calc(30% - 50px);
    width: 100px;
    height: 70px;
  }
}
.home main.global .method .method_01:after {
  content: "";
  position: absolute;
  bottom: -69px;
  left: calc(30% - 50px);
  width: 100px;
  height: 70px;
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='140' viewBox='0 0 200 140'%3E%3Cpath fill='%232C53BE' d='M161.777,38.552A38.391,38.391,0,0,1,200,0H0A38.391,38.391,0,0,1,38.223,38.552h.045V40a118.462,118.462,0,0,1-1.974,22.172,65.625,65.625,0,0,0-1.166,13.665C35.8,111.221,64.6,139.863,99.686,140c35.98.181,65.186-29.185,65.186-65.429a68.4,68.4,0,0,0-1.032-11.81,125.535,125.535,0,0,1-2.109-22.036V38.507h.045Z'/%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 10;
}
@media only screen and (max-width: 800px) {
  .home main.global .method .method_01 .grid-item {
    flex-basis: 100%;
    width: 100%;
  }
  .home main.global .method .method_01 article {
    order: 1;
  }
  .home main.global .method .method_01 figure {
    order: 0;
    margin-bottom: 60px;
  }
}
.home main.global .method .method_01 h4 {
  margin-top: 60px;
}
.home main.global .method .method_02 {
  flex-basis: calc(45% - 60px);
  width: calc(45% - 30px);
  background-color: #152248;
  color: white;
}
.home main.global .method .method_02:after {
  content: "";
  position: absolute;
  top: calc(50% - 50px);
  right: -84px;
  width: 100px;
  height: 70px;
  transform: rotate(-90deg);
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='140' viewBox='0 0 200 140'%3E%3Cpath fill='%23152248' d='M161.777,38.552A38.391,38.391,0,0,1,200,0H0A38.391,38.391,0,0,1,38.223,38.552h.045V40a118.462,118.462,0,0,1-1.974,22.172,65.625,65.625,0,0,0-1.166,13.665C35.8,111.221,64.6,139.863,99.686,140c35.98.181,65.186-29.185,65.186-65.429a68.4,68.4,0,0,0-1.032-11.81,125.535,125.535,0,0,1-2.109-22.036V38.507h.045Z'/%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 10;
}
@media only screen and (max-width: 800px) {
  .home main.global .method .method_02:after {
    top: calc(100% - 1px);
    right: calc(30% - 35px);
    transform: rotate(0deg);
  }
}
@media only screen and (max-width: 1200px) {
  .home main.global .method .method_02 .grid-item {
    flex-basis: 100%;
    width: 100%;
  }
  .home main.global .method .method_02 figure {
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 800px) {
  .home main.global .method .method_02 {
    flex-basis: 100%;
    width: 100%;
  }
}
.home main.global .method .method_03 {
  padding-left: 80px;
  flex-basis: calc(55% - 110px);
  width: calc(55% - 110px);
  background-color: white;
}
@media only screen and (max-width: 1200px) {
  .home main.global .method .method_03 .grid-item {
    flex-basis: 100%;
    width: 100%;
  }
  .home main.global .method .method_03 article {
    order: 1;
  }
  .home main.global .method .method_03 figure {
    order: 0;
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 800px) {
  .home main.global .method .method_03 {
    padding-left: 30px;
    flex-basis: 100%;
    width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  .home main.global .method .method_03 {
    padding-left: 0;
    padding-right: 0;
  }
}
.home main.global .method .method_03:after {
  content: "";
  position: absolute;
  bottom: -140px;
  right: calc(50% - 100px);
  width: 200px;
  height: 140px;
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='140' viewBox='0 0 200 140'%3E%3Cpath fill='white' d='M161.777,38.552A38.391,38.391,0,0,1,200,0H0A38.391,38.391,0,0,1,38.223,38.552h.045V40a118.462,118.462,0,0,1-1.974,22.172,65.625,65.625,0,0,0-1.166,13.665C35.8,111.221,64.6,139.863,99.686,140c35.98.181,65.186-29.185,65.186-65.429a68.4,68.4,0,0,0-1.032-11.81,125.535,125.535,0,0,1-2.109-22.036V38.507h.045Z'/%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media only screen and (max-width: 800px) {
  .home main.global .method .method_03:after {
    bottom: -69px;
    left: calc(50% - 50px);
    width: 100px;
    height: 70px;
  }
}
.home main.global .clients {
  padding: 200px 0 100px 0;
}
@media only screen and (max-width: 800px) {
  .home main.global .clients {
    padding-top: 130px;
  }
}
.home main.global .clients header {
  text-align: center;
  margin-bottom: 50px;
}
.home main.global .clients header h3 {
  display: inline-block;
  font-family: "Fira Sans", Helvetica, Arial, sans-serif;
  font-size: 3em;
  font-weight: 100;
  width: 100%;
  margin-bottom: 15px;
}
@media only screen and (max-width: 760px) {
  .home main.global .clients header h3 {
    font-size: 2.5em;
  }
}
.home main.global .clients header h3 strong {
  font-weight: 100;
  border-bottom: 2px solid #D90000;
}
.home main.global .clients header p {
  display: inline-block;
  font-family: "Fira Sans", Helvetica, Arial, sans-serif;
  font-size: 1.6em;
  line-height: 1.4em;
  font-weight: 300;
  margin-bottom: 15px;
}
.home main.global .clients article ul {
  justify-content: center;
}
.home main.global .clients article ul li {
  list-style: none;
  padding: 10px;
  border: 1px solid #ccc;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  margin: 10px;
}
.home main.global .clients article ul li:hover {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
  transform: translate3d(0, -5px, 0);
  transform-style: flat;
  text-decoration: none;
}
.home main.global .clients article ul li img {
  display: block;
  width: 100%;
  height: auto;
}

.page.about main.global {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  text-decoration: none;
  display: block;
  width: 100%;
}
.page.about main.global:hover {
  text-decoration: none;
}
.page.about main.global .featured {
  background-color: #31aa13;
}
.page.about main.global .about-intro figcaption {
  margin-top: 60px;
}
.page.about main.global .about-principles {
  background-color: #31aa13;
}
.page.about main.global .about-principles:before, .page.about main.global .about-principles:after {
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='140' viewBox='0 0 200 140'%3E%3Cpath fill='%2331aa13' d='M161.777,38.552A38.391,38.391,0,0,1,200,0H0A38.391,38.391,0,0,1,38.223,38.552h.045V40a118.462,118.462,0,0,1-1.974,22.172,65.625,65.625,0,0,0-1.166,13.665C35.8,111.221,64.6,139.863,99.686,140c35.98.181,65.186-29.185,65.186-65.429a68.4,68.4,0,0,0-1.032-11.81,125.535,125.535,0,0,1-2.109-22.036V38.507h.045Z'/%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.page.about main.global .about-principles h3 {
  color: white;
  text-align: center;
}
.page.about main.global .about-principles article {
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  background: white;
  border-radius: 5px;
  padding: 20px;
}
.page.about main.global .about-principles article:hover {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
  transform: translate3d(0, -5px, 0);
  transform-style: flat;
  text-decoration: none;
}
.page.about main.global .about-team {
  text-align: center;
}
.page.about main.global .about-team ul {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  text-decoration: none;
  list-style: none;
  justify-content: center;
}
.page.about main.global .about-team ul:hover {
  text-decoration: none;
}
.page.about main.global .about-team ul li {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  text-decoration: none;
  padding: 20px;
  list-style: none;
}
.page.about main.global .about-team ul li:hover {
  text-decoration: none;
}
.page.about main.global .about-team ul li figure {
  margin: 0;
}
.page.about main.global .about-team ul li img {
  border-radius: 400px;
  margin-bottom: 30px;
}
.page.about main.global .about-team ul li em {
  display: block;
}
.page.about main.global .about-contact {
  text-align: center;
}

.page.work main.global {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  text-decoration: none;
  display: block;
  width: 100%;
}
.page.work main.global:hover {
  text-decoration: none;
}
.page.work main.global .featured {
  background-color: #2C53BE;
}
.page.work main.global .featured:after {
  left: calc(30% - 50px);
}
.page.work main.global .work-intro ul {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  text-decoration: none;
  list-style: none;
  margin-top: 60px;
}
.page.work main.global .work-intro ul:hover {
  text-decoration: none;
}
.page.work main.global .work-intro li {
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  display: block;
  padding: 30px;
  background: white;
  border-radius: 3px;
  box-shadow: 0 20px 20px rgba(50, 50, 50, 0.1);
  margin-bottom: 30px;
  background-color: white;
  border: 1px solid rgba(100, 100, 100, 0.1);
  border-bottom: 2px solid #2C53BE;
  display: flex;
  padding: 0;
}
.page.work main.global .work-intro li:hover {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
  transform: translate3d(0, -5px, 0);
  transform-style: flat;
  text-decoration: none;
}
.page.work main.global .work-intro li a {
  font-family: "Fira Sans", Helvetica, Arial, sans-serif;
  font-size: 1.8em;
  color: black;
  padding: 30px 20px;
  flex-basis: 100%;
  width: 100%;
  height: 100%;
}
.page.work main.global .work-process {
  margin-top: 120px;
}
.page.work main.global .work-process header {
  text-align: center;
  margin-bottom: 60px;
}
.page.work main.global .work-process figure {
  margin: 0;
}
.page.work main.global .work-process img {
  max-height: 320px;
}

.page.product main.global {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  text-decoration: none;
  display: block;
  width: 100%;
}
.page.product main.global:hover {
  text-decoration: none;
}
.page.product main.global .featured {
  background-color: #152248;
}
.page.product main.global .featured article {
  text-align: left;
}
.page.product main.global .featured:after {
  left: calc(90% - 50px);
}
.page.product main.global .content .box {
  margin: 40px 0;
}
.page.product main.global .content figure {
  margin: 0;
}
.page.product main.global .content figure.grid-item {
  margin-bottom: 30px;
}
.page.product main.global .content figure.fig-full {
  margin: 40px 0;
}
.page.product main.global .content figure img {
  border-radius: 3px;
  box-shadow: 0 20px 20px rgba(50, 50, 50, 0.1);
}
.page.product main.global .content figure figcaption {
  margin-top: 30px;
  display: inline-block;
  text-align: left;
  font-size: 90%;
  color: #666;
}
.page.product main.global .content figure.caption-right {
  text-align: right;
}
.page.product main.global .content figure.caption-right figcaption {
  width: 50%;
}
.page.product main.global .content figure.caption-left {
  text-align: left;
}
.page.product main.global .content figure.caption-left figcaption {
  width: 50%;
}
.page.product main.global .content .box-list {
  margin-top: 40px;
  margin-bottom: 40px;
}
.page.product main.global .content .box-list strong {
  display: block;
}

.page.case {
  background-color: rgba(200, 200, 200, 0.2);
}
.page.case main.global {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  text-decoration: none;
  display: block;
  width: 100%;
}
.page.case main.global:hover {
  text-decoration: none;
}
.page.case main.global .featured {
  padding: 150px 15px 80px 15px;
  background-color: #ff7700;
  margin-bottom: 0;
}
.page.case main.global .featured article {
  text-align: left;
}
.page.case main.global .featured:after {
  bottom: -69px;
  left: calc(80% - 50px);
  transform: rotate(0deg);
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='140' viewBox='0 0 200 140'%3E%3Cpath fill='%23ff7700' d='M161.777,38.552A38.391,38.391,0,0,1,200,0H0A38.391,38.391,0,0,1,38.223,38.552h.045V40a118.462,118.462,0,0,1-1.974,22.172,65.625,65.625,0,0,0-1.166,13.665C35.8,111.221,64.6,139.863,99.686,140c35.98.181,65.186-29.185,65.186-65.429a68.4,68.4,0,0,0-1.032-11.81,125.535,125.535,0,0,1-2.109-22.036V38.507h.045Z'/%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 10;
}
.page.case main.global .intro {
  position: relative;
  padding: 90px 0 30px 0;
  display: block;
  background: white;
  margin-bottom: 120px;
}
.page.case main.global .intro h2, .page.case main.global .intro h3, .page.case main.global .intro h4, .page.case main.global .intro h5 {
  font-weight: 100;
  font-family: "Fira Sans", Helvetica, Arial, sans-serif;
}
.page.case main.global .intro h3 {
  font-size: 2.8em;
  margin-top: 60px;
  margin-bottom: 30px;
}
.page.case main.global .intro h3:first-of-type {
  margin-top: 0;
}
.page.case main.global .intro h4 {
  font-family: "Fira Sans", Helvetica, Arial, sans-serif;
  font-size: 2.2em;
  margin-bottom: 20px;
}
.page.case main.global .intro h5 {
  font-family: "Fira Sans", Helvetica, Arial, sans-serif;
  font-size: 2em;
  margin-bottom: 20px;
}
.page.case main.global .intro p, .page.case main.global .intro blockquote {
  font-size: 1.3em;
  line-height: 1.8em;
  margin-bottom: 20px;
}
.page.case main.global .intro blockquote {
  padding-left: 30px;
  font-size: 1.4em;
  font-style: italic;
  font-weight: 100;
}
@media only screen and (max-width: 480px) {
  .page.case main.global .intro blockquote {
    padding-left: 0px;
  }
}
.page.case main.global .intro ul, .page.case main.global .intro ol {
  margin-left: 20px;
  margin-bottom: 20px;
}
.page.case main.global .intro ul li, .page.case main.global .intro ol li {
  font-size: 1.2em;
  line-height: 1.8em;
}
.page.case main.global .intro .player {
  margin-bottom: 30px;
}
.page.case main.global .intro figure {
  text-align: center;
  width: 100%;
  margin: 60px auto;
}
.page.case main.global .intro figure img {
  display: block;
  width: 100%;
  height: auto;
}
.page.case main.global .intro figure.left {
  width: 35%;
  max-width: 320px;
  margin: 30px 60px 30px;
  float: left;
}
@media only screen and (max-width: 480px) {
  .page.case main.global .intro figure.left {
    float: none;
    width: 100%;
    margin: 40px auto;
  }
}
.page.case main.global .intro figure.right {
  width: 35%;
  max-width: 320px;
  margin: 30px 30px 30px 60px;
  float: right;
}
@media only screen and (max-width: 480px) {
  .page.case main.global .intro figure.right {
    float: none;
    width: 100%;
    margin: 40px auto;
  }
}
.page.case main.global .intro:after {
  content: "";
  position: absolute;
  bottom: -69px;
  left: calc(20% - 50px);
  width: 100px;
  height: 70px;
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='140' viewBox='0 0 200 140'%3E%3Cpath fill='white' d='M161.777,38.552A38.391,38.391,0,0,1,200,0H0A38.391,38.391,0,0,1,38.223,38.552h.045V40a118.462,118.462,0,0,1-1.974,22.172,65.625,65.625,0,0,0-1.166,13.665C35.8,111.221,64.6,139.863,99.686,140c35.98.181,65.186-29.185,65.186-65.429a68.4,68.4,0,0,0-1.032-11.81,125.535,125.535,0,0,1-2.109-22.036V38.507h.045Z'/%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.page.case main.global .intro ul li {
  margin-bottom: 20px;
}
.page.case main.global .intro ul li strong {
  display: block;
}
.page.case main.global .process {
  position: relative;
  background: white;
  padding: 80px 0 50px 0;
  margin: 100px 0;
}
.page.case main.global .process h2, .page.case main.global .process h3, .page.case main.global .process h4, .page.case main.global .process h5 {
  font-weight: 100;
  font-family: "Fira Sans", Helvetica, Arial, sans-serif;
}
.page.case main.global .process h3 {
  font-size: 2.8em;
  margin-top: 60px;
  margin-bottom: 30px;
}
.page.case main.global .process h3:first-of-type {
  margin-top: 0;
}
.page.case main.global .process h4 {
  font-family: "Fira Sans", Helvetica, Arial, sans-serif;
  font-size: 2.2em;
  margin-bottom: 20px;
}
.page.case main.global .process h5 {
  font-family: "Fira Sans", Helvetica, Arial, sans-serif;
  font-size: 2em;
  margin-bottom: 20px;
}
.page.case main.global .process p, .page.case main.global .process blockquote {
  font-size: 1.3em;
  line-height: 1.8em;
  margin-bottom: 20px;
}
.page.case main.global .process blockquote {
  padding-left: 30px;
  font-size: 1.4em;
  font-style: italic;
  font-weight: 100;
}
@media only screen and (max-width: 480px) {
  .page.case main.global .process blockquote {
    padding-left: 0px;
  }
}
.page.case main.global .process ul, .page.case main.global .process ol {
  margin-left: 20px;
  margin-bottom: 20px;
}
.page.case main.global .process ul li, .page.case main.global .process ol li {
  font-size: 1.2em;
  line-height: 1.8em;
}
.page.case main.global .process .player {
  margin-bottom: 30px;
}
.page.case main.global .process figure {
  text-align: center;
  width: 100%;
  margin: 60px auto;
}
.page.case main.global .process figure img {
  display: block;
  width: 100%;
  height: auto;
}
.page.case main.global .process figure.left {
  width: 35%;
  max-width: 320px;
  margin: 30px 60px 30px;
  float: left;
}
@media only screen and (max-width: 480px) {
  .page.case main.global .process figure.left {
    float: none;
    width: 100%;
    margin: 40px auto;
  }
}
.page.case main.global .process figure.right {
  width: 35%;
  max-width: 320px;
  margin: 30px 30px 30px 60px;
  float: right;
}
@media only screen and (max-width: 480px) {
  .page.case main.global .process figure.right {
    float: none;
    width: 100%;
    margin: 40px auto;
  }
}
.page.case main.global .process:before {
  content: "";
  position: absolute;
  top: -69px;
  left: calc(80% - 50px);
  width: 100px;
  height: 70px;
  transform: rotate(180deg);
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='140' viewBox='0 0 200 140'%3E%3Cpath fill='white' d='M161.777,38.552A38.391,38.391,0,0,1,200,0H0A38.391,38.391,0,0,1,38.223,38.552h.045V40a118.462,118.462,0,0,1-1.974,22.172,65.625,65.625,0,0,0-1.166,13.665C35.8,111.221,64.6,139.863,99.686,140c35.98.181,65.186-29.185,65.186-65.429a68.4,68.4,0,0,0-1.032-11.81,125.535,125.535,0,0,1-2.109-22.036V38.507h.045Z'/%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.page.case main.global .process:after {
  content: "";
  position: absolute;
  bottom: -69px;
  left: calc(20% - 50px);
  width: 100px;
  height: 70px;
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='140' viewBox='0 0 200 140'%3E%3Cpath fill='white' d='M161.777,38.552A38.391,38.391,0,0,1,200,0H0A38.391,38.391,0,0,1,38.223,38.552h.045V40a118.462,118.462,0,0,1-1.974,22.172,65.625,65.625,0,0,0-1.166,13.665C35.8,111.221,64.6,139.863,99.686,140c35.98.181,65.186-29.185,65.186-65.429a68.4,68.4,0,0,0-1.032-11.81,125.535,125.535,0,0,1-2.109-22.036V38.507h.045Z'/%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.page.case main.global .process h3 {
  display: block;
  text-align: center;
}
.page.case main.global .process h4 {
  margin-top: 40px;
}
.page.case main.global .process p {
  padding-left: 30px;
}
.page.case main.global .content .box {
  margin: 40px 0;
}
.page.case main.global .content figure {
  margin: 0;
}
.page.case main.global .content figure.grid-item {
  margin-bottom: 30px;
}
.page.case main.global .content figure.fig-full {
  margin: 40px 0;
}
.page.case main.global .content figure img {
  border-radius: 3px;
  box-shadow: 0 20px 20px rgba(50, 50, 50, 0.1);
}
.page.case main.global .content figure figcaption {
  margin-top: 30px;
  display: inline-block;
  text-align: left;
  font-size: 90%;
  color: #666;
}
.page.case main.global .content figure.caption-right {
  text-align: right;
}
.page.case main.global .content figure.caption-right figcaption {
  width: 50%;
}
.page.case main.global .content figure.caption-left {
  text-align: left;
}
.page.case main.global .content figure.caption-left figcaption {
  width: 50%;
}
.page.case main.global .content .box-list {
  margin-top: 40px;
  margin-bottom: 40px;
}
.page.case main.global .content .box-list strong {
  display: block;
}
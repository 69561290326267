// ----------------------------------------------------------------------------
// Paths
// ----------------------------------------------------------------------------
$assetPath : "../img"


// ----------------------------------------------------------------------------
// Colors
// ----------------------------------------------------------------------------

$color-basic-white: #fff
$color-basic-black: rgba(0, 0, 0, 1)

$color-basic-green: #31aa13  
$color-basic-blue: #2C53BE 
$color-basic-blue_link: #5c57ff
$color-basic-blue_light: #B5D3FF
$color-basic-blue_dark: #152248
$color-basic-aqua: #00bee5 
$color-basic-cyan: #47d8aa 
$color-basic-purple: #ab61ff
$color-basic-pink: rgb(255, 117, 152)
$color-basic-red: #D90000
$color-basic-orange: #ff7700 
$color-basic-yellow: #ffbd14 

$color-form-action: #31aa13
$color-form-confirm: #5c57ff
$color-form-warning: #F19003
$color-form-error: #F12803
$color-form-cancel: #E0296F
$color-form-check: #0AD5EE
$color-form-checked: #8BF2FF

$color-form-back: #666

// ----------------------------------------------------------------------------
// Widths
// ----------------------------------------------------------------------------

$width-huge: 1440px
$width-big: 1280px
$width-medium: 1024px
$width-regular: 960px
$width-small: 720px
$width-micro: 480px

// ----------------------------------------------------------------------------
// Borders
// ----------------------------------------------------------------------------

$border-radius-sm: 5px
$border-radius-md: 7px
$border-radius-lg: 10px
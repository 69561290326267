.page.product
	main.global
		+reset
		display: block
		width: 100%
		.featured
			background-color: $color-basic-blue-dark
			article
				text-align: left
			&:after
				left: calc( 90% - 50px )
		.content
			.box
				margin: 40px 0
			figure
				margin: 0
				&.grid-item
					margin-bottom: 30px
				&.fig-full
					margin: 40px 0
				img
					border-radius: 3px
					box-shadow: 0 20px 20px rgba(50,50,50,0.1)
				figcaption
					margin-top: 30px
					display: inline-block
					text-align: left
					font-size: 90%
					color: #666
				&.caption-right
					text-align: right
					figcaption
						width: 50%
				&.caption-left
					text-align: left
					figcaption
						width: 50%
			.box-list
				margin-top: 40px
				margin-bottom: 40px
				strong
					display: block
=content_title
	+font_title
	display: block
	font-size: 2.4em
	line-height: 1.4em
	margin-bottom: 15px

=content_text
	+font_body
	font-size: 1.2em
	font-weight: 300
	line-height: 1.4em

=content_basic
	h2,h3,h4,h5
		font-weight: 100
		+font_title
	h3
		font-size: 2.8em
		margin-top: 60px
		margin-bottom: 30px
		&:first-of-type
			margin-top: 0
	h4
		+font_title
		font-size: 2.2em
		margin-bottom: 20px
	h5
		+font_title
		font-size: 2em
		margin-bottom: 20px
	p, blockquote
		font-size: 1.3em
		line-height: 1.8em
		margin-bottom: 20px
	blockquote
		padding-left: 30px
		font-size: 1.4em
		font-style: italic
		font-weight: 100
		@media only screen and (max-width : 480px)
			padding-left: 0px
	ul, ol
		margin-left: 20px
		margin-bottom: 20px
		li
			font-size: 1.2em
			line-height: 1.8em
	.player
		margin-bottom: 30px
	figure
		text-align: center
		width: 100%
		margin: 60px auto
		img
			display: block
			width: 100%
			height: auto
		&.left
			width: 35%
			max-width: 320px
			margin: 30px 60px 30px
			float: left
			@media only screen and (max-width : 480px)
				float: none
				width: 100%
				margin: 40px auto
		&.right
			width: 35%
			max-width: 320px
			margin: 30px 30px 30px 60px
			float: right
			@media only screen and (max-width : 480px)
				float: none
				width: 100%
				margin: 40px auto

=title_huge
	display: inline-block
	+font_title
	font-size: 3em
	font-weight: 100
	width: 100%
	@media only screen and (max-width : 760px)
		font-size: 2.5em
	strong
		font-weight: 100
		border-bottom: 2px solid $color-basic-red
=title_big
	display: inline-block
	+font_title
	font-size: 1.8em
	font-weight: 100
=text_big
	display: inline-block
	+font_title
	font-size: 1.6em
	line-height: 1.4em
	font-weight: 300
=text_normal
	display: inline-block
	+font_title
	font-size: 1.4em
	line-height: 1.4em
	font-weight: 300
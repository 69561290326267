// ----------------------------------------------------------------------------
// Reusable Generic Classes
// ----------------------------------------------------------------------------

=reset
	box-sizing: border-box
	margin: 0
	padding: 0
	border: 0
	text-decoration: none
	&:hover
		text-decoration: none

=box_reset
	position: relative
	display: inline-block
	box-sizing: border-box
	width: auto
	height: auto
	margin: 0
	padding: 0
	border: 0

=link_reset
	text-decoration: none
	color: inherit
	&:hover
		text-decoration: none

=input_reset
	-webkit-appearance: none
	-moz-appearance: none
	&:focus
		outline: none

=scrollable
	box-sizing: border-box
	-webkit-overflow-scrolling: touch
	-moz-overflow-scrolling: touch

=hide_text
	display: inline-block
	overflow: hidden
	white-space: nowrap
	text-indent: 100%

=ratio
	box-sizing: border-box
	position: relative
	width: 100%
	display: block
	&.ratio1x1
		padding-bottom: 100%
	&.ratio4x3
		padding-bottom: 75%
	&.ratio3x2
		padding-bottom: 66.66%
	&.ratio16x9
		padding-bottom: 56.25%
	img, iframe, div, video
		+reset
		box-sizing: border-box
		display: block
		width: 100%
		height: 100%
		position: absolute
		top: 0
		right: 0
		bottom: 0
		left: 0

// ----------------------------------------------------------------------------
// General Styling
// ----------------------------------------------------------------------------

=box_hover
	transition: all .3s ease-in-out
	text-decoration: none
	&:hover
		box-shadow: 0 10px 10px rgba(0,0,0,0.3)
		transform: translate3d(0, -5px, 0)
		transform-style: flat
		text-decoration: none

=box_shadow
	display: block
	padding: 30px
	background: white
	border-radius: 3px
	box-shadow: 0 20px 20px rgba(50,50,50,0.1)
	margin-bottom: 30px

=item_hover
	transition: all .3s ease-in-out
	cursor: pointer
	text-decoration: none
	&:hover
		transform: translate3d(0, -5px, 0) scale3d(1.1, 1.1, 1.1)
		transform-style: flat
		text-decoration: none
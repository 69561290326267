// ----------------------------------------------------------------------------
// Animations
// ----------------------------------------------------------------------------

.animated
	-webkit-backface-visibility: visible !important
	backface-visibility: visible !important
	@media only screen and (max-width : 700px)
		animation: none!important
	&.play
		animation-fill-mode: both
		animation-timing-function: ease-in-out
		&.fadeIn
			animation-name: fadeIn
			animation-delay: .5s
			animation-duration: .5s
		&.slideUp
			animation-name: slideUp
			animation-delay: .5s
			animation-duration: .5s
		&.slideDown
			animation-name: slideDown
			animation-delay: .5s
			animation-duration: .5s
		&.slideIn
			animation-name: slideInRight
			animation-delay: .5s
			animation-duration: .5s
			&:nth-of-type(1), &:nth-of-type(2)
				animation-name: slideInLeft

@keyframes fadeIn
	from
		opacity: 0
	to
		opacity: 1

@keyframes slideDown
	from
		transform: translate3d(0,-50px, 0)
		opacity: 0
	to
		transform: translate3d(0,0,0)
		opacity: 1

@keyframes slideUp
	from
		transform: translate3d(0,50px, 0)
		opacity: 0
	to
		transform: translate3d(0,0,0)
		opacity: 1

@keyframes slideInLeft
	from
		transform: translate3d(-50px, 0, 0)
		opacity: 0
	to
		transform: translate3d(0,0,0)
		opacity: 1

@keyframes slideInRight
	from
		transform: translate3d(50px, 0, 0)
		opacity: 0
	to
		transform: translate3d(0,0,0)
		opacity: 1

@keyframes fadeIn
	0%
		opacity: 0
	100%
		opacity: 1

@keyframes goUp
	0%
		opacity: 1
		top: 0px
	100%
		opacity: 0
		top: -200px

@keyframes goDown
	0%
		opacity: 0
		top: -200px
	100%
		opacity: 1
		top: 0px

@keyframes hover_horizontal_left
	0%
		transform: translateX(0px)
	50%
		transform: translateX(-10px)
	100%
		transform: translateX(0px)

@keyframes hover_horizontal_right
	0%
		transform: translateX(0px)
	50%
		transform: translateX(10px)
	100%
		transform: translateX(0px)

@keyframes hovering
	0%
		transform: translateY(0px)
	50%
		transform: translateY(-8px)
	100%
		transform: translateY(0px)

@keyframes bounce
	from
		transform: translateY(0px)
	to
		transform: translateY(-5px)
.page.about
	main.global
		+reset
		display: block
		width: 100%
		.featured
			background-color: $color-basic-green
		.about
			&-intro
				figcaption
					margin-top: 60px
			&-principles
				background-color: $color-basic-green
				&:before, &:after
					@include shape_puzzle($color-basic-green)
				h3
					color: white
					text-align: center
				article
					+box_hover
					background: white
					border-radius: 5px
					padding: 20px
			&-team
				text-align: center
				ul
					+reset
					list-style: none
					justify-content: center
					li
						+reset
						padding: 20px
						list-style: none
						figure
							margin: 0
						img
							border-radius: 400px
							margin-bottom: 30px
						em
							display: block
			&-contact
				text-align: center
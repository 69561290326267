.clocks
  .clock
    border-radius: 50%
    background: radial-gradient(#000, #000 0.1em, #fff 0.1em, #fff), #fff
    display: inline-block
    margin: 1%
    padding-bottom: 25%
    margin-right: 20px
    margin-bottom: 60px
    position: relative
    top: 50%
    width: 25%
    opacity: 1
    transform: translateY(0%)
    &::after
      background: red
      border-radius: 50%
      content: ""
      position: absolute
      left: 50%
      top: 50%
      transform: translate(-50%, -50%)
      width: 4%
      height: 4%
      z-index: 10
    &.station
      background-size: 95%
      box-shadow: 0 0 0.5em rgba(0,0,0,0.2) inset
      .hours
        background: #000
        width: 3.5%
        height: 40%
        position: absolute
        left: 48.25%
        top: 22%
        transform-origin: 50% 71%
        &.angled
          transform: rotateZ(-40deg)
      .minutes
        background: #000
        width: 3.5%
        height: 55%
        position: absolute
        left: 48.25%
        top: 7%
        transform-origin: 50% 78.5%
        &.angled
          transform: rotateZ(40deg)
    .label
      position: absolute
      bottom: -40px
      width: 100%
      text-align: center
    .minutes-container, .hours-container
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0
    .hours-container
      animation: rotate 43200s infinite linear
  &.bounce
    .minutes-container
      transition: transform 0.3s cubic-bezier(.4,2.08,.55,.44)
  .steps
    .minutes-container
      animation: rotate 3600s infinite steps(60)
  .local.steps
    .minutes-container
      animation: none
  .clock.night
    background: radial-gradient(#fff, #fff 0.1em, #444 0.1em, #444), #444
    &.station
      .hours, .minutes
        background: white
@keyframes rotate
  100%
    transform: rotateZ(360deg)
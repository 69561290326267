
// ----------------------------------------------------------------------------
// Fonts
// ----------------------------------------------------------------------------

@import url('https://fonts.googleapis.com/css?family=Fira+Sans:300,400,700|Montserrat:400,400i,700,700i')

=font_title
  font-family: 'Fira Sans', Helvetica, Arial, sans-serif

=font_body
  font-family: 'Montserrat', Helvetica, Arial, sans-serif
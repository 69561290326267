.page.case
	background-color: rgba(200,200,200,0.2)
	main.global
		+reset
		display: block
		width: 100%
		.featured
			padding: 150px 15px 80px 15px
			background-color: $color-basic-orange
			margin-bottom: 0
			article
				text-align: left
			&:after
				bottom: -69px
				left: calc( 80% - 50px )
				transform: rotate(0deg)
				@include shape_puzzle($color-basic-orange)
				z-index: 10
		.intro
			position: relative
			padding: 90px 0 30px 0
			display: block
			background: white
			+content_basic
			margin-bottom: 120px
			&:after
				content: ''
				position: absolute
				bottom: -69px
				left: calc( 20% - 50px )
				width: 100px
				height: 70px
				@include shape_puzzle(white)
			ul
				li
					margin-bottom: 20px
					strong
						display: block
		.process
			position: relative
			background: white
			padding: 80px 0 50px 0
			margin: 100px 0
			+content_basic
			&:before
				content: ''
				position: absolute
				top: -69px
				left: calc( 80% - 50px )
				width: 100px
				height: 70px
				transform: rotate(180deg)
				@include shape_puzzle(white)
			&:after
				content: ''
				position: absolute
				bottom: -69px
				left: calc( 20% - 50px )
				width: 100px
				height: 70px
				@include shape_puzzle(white)
			h3
				display: block
				text-align: center
			h4
				margin-top: 40px
			p
				padding-left: 30px
		.content
			.box
				margin: 40px 0
			figure
				margin: 0
				&.grid-item
					margin-bottom: 30px
				&.fig-full
					margin: 40px 0
				img
					border-radius: 3px
					box-shadow: 0 20px 20px rgba(50,50,50,0.1)
				figcaption
					margin-top: 30px
					display: inline-block
					text-align: left
					font-size: 90%
					color: #666
				&.caption-right
					text-align: right
					figcaption
						width: 50%
				&.caption-left
					text-align: left
					figcaption
						width: 50%
			.box-list
				margin-top: 40px
				margin-bottom: 40px
				strong
					display: block
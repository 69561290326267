.page.work
  main.global
    +reset
    display: block
    width: 100%
    .featured
      background-color: $color-basic-blue
      &:after
        left: calc( 30% - 50px )
    .work
      &-intro
        ul
          +reset
          list-style: none
          margin-top: 60px
        li
          +box_hover
          +box_shadow
          background-color: white
          border: 1px solid rgba(100,100,100,0.1)
          border-bottom: 2px solid $color-basic-blue
          display: flex
          padding: 0
          a
            +font-title
            font-size: 1.8em
            color: black
            padding: 30px 20px
            flex-basis: 100%
            width: 100%
            height: 100%
      &-process
        margin-top: 120px
        header
          text-align: center
          margin-bottom: 60px
        figure
          margin: 0
        img
          max-height: 320px
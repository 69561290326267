.home
	main.global
		+reset
		display: block
		width: 100%
		.featured
			position: relative
			padding: 200px 15px 50px 15px
			background-color: white
			text-align: center
			@media only screen and (max-width : 720px)
				padding-top: 120px
			&:after
				content: ''
				position: absolute
				bottom: -139px
				left: calc( 30% - 100px )
				width: 200px
				height: 140px
				@include shape_puzzle(white)
				@media only screen and (max-width : 1024px)
					left: calc( 20% - 50px )
				@media only screen and (max-width : 800px)
					bottom: -69px
					left: calc( 30% - 50px )
					width: 100px
					height: 70px
			article
				max-width: 720px
				margin: auto
				padding-bottom: 40px
				text-align: center
				@media only screen and (max-width : 640px)
					margin-bottom: 60px
				h2
					+title_huge
					margin-bottom: 60px
					@media only screen and (max-width : 640px)
						margin-bottom: 30px
				p
					+title_big
			aside
				padding-bottom: 30%
				position: relative
				transform:  perspective(800px) rotateY(-5deg)
				transition: transform 1s ease-in-out
				@media only screen and (max-width : 640px)
					padding-bottom: 60%
				&:hover
					transform:  perspective(500px) rotateY(-5deg)
					figure.fig_1
						transform: translate(45px, -60px)
					figure.fig_2
						transform: scale(.95) translate(5px, -30px)
				figure
					position: absolute
					display: block
					left: 0
					top: 60px
					right: 60px
					bottom: 0
					transition: transform 1s ease-in-out
					&.fig_1
						transform: translate(30px, -60px)
					&.fig_2
						transform: scale(.95) translate(0px, -30px)
					&.fig_3
						transform: scale(.9) translate(-30px, 0)
		.tools
			padding: 100px 0 250px 0
			background-image: url('#{$assetPath}/textures/texture_shapes.svg')
			background-repeat: repeat
			background-size: 240px 240px
			@media only screen and (max-width : 800px)
				padding-bottom: 100px
			header
				display: block
				text-align: right
				margin-bottom: 60px
				@media only screen and (max-width : 540px)
					text-align: left
				h3
					+title_huge
					margin-bottom: 15px
				p
					+text_big
					max-width: 550px
			a
				color: black
			article
				display: block
				padding: 20px 20px 40px 20px
				background: white
				border-radius: 3px
				box-shadow: 0 5px 5px rgba(0,0,0,0.1)
				margin-bottom: 30px
				+box_hover
				h4
					+title_big
					margin-bottom: 20px
				p
					+text_normal
				&.cat
					&-fundamentals
						border-bottom: 3px solid $color-basic-aqua
					&-events
						border-bottom: 3px solid $color-basic-yellow
					&-grants
						border-bottom: 3px solid $color-basic-green
					&-cases
						border-bottom: 3px solid $color-basic-orange
					&-learning
						border-bottom: 3px solid $color-basic-purple
		.method
			h3
				+title_huge
				display: block
				margin-bottom: 20px
			h4
				+title_big
				display: block
				margin-bottom: 20px
			p
				+text_normal
				display: block
				margin-bottom: 20px
			.box
				position: relative
				padding: 100px 30px
				@media only screen and (max-width : 480px)
					padding-left: 0
					padding-right: 0
				article
					.info, .btn
						margin-left: 20px
				.grid
					&.columns
						align-items: middle
						align-content: center
						.grid-item
							align-self: center
					&.rightToLeft
						flex-direction: row-reverse
				figure
					img
						display: block
						margin: auto
						width: auto
						height: 100%
						max-height: 350px
			.method_01
				flex-basis: 100%
				width: 100%
				background-color: $color-basic-blue
				background-image: url('#{$assetPath}/textures/texture_dots.svg')
				background-repeat: repeat
				background-size: 900px
				background-position: center bottom
				color: white
				&:before
					content: ''
					position: absolute
					top: -139px
					right: calc( 30% - 100px )
					width: 200px
					height: 140px
					transform: rotate(180deg)
					@include shape_puzzle($color-basic-blue)
					@media only screen and (max-width : 800px)
						top: -69px
						right: calc( 30% - 50px )
						width: 100px
						height: 70px
				&:after
					content: ''
					position: absolute
					bottom: -69px
					left: calc( 30% - 50px )
					width: 100px
					height: 70px
					@include shape_puzzle($color-basic-blue)
					z-index: 10
				@media only screen and (max-width : 800px)
					.grid-item
						flex-basis: 100%
						width: 100%
					article
							order: 1
					figure
							order: 0
							margin-bottom: 60px
				h4
					margin-top: 60px
			.method_02
				flex-basis: calc(45% - 60px)
				width: calc(45% - 30px)
				background-color: $color-basic-blue_dark
				color: white
				&:after
					content: ''
					position: absolute
					top: calc( 50% - 50px )
					right: -84px
					width: 100px
					height: 70px
					transform: rotate(-90deg)
					@include shape_puzzle($color-basic-blue_dark)
					z-index: 10
					@media only screen and (max-width : 800px)
							top: calc( 100% - 1px )
							right: calc( 30% - 35px )
							transform: rotate(0deg)
				@media only screen and (max-width : 1200px)
					.grid-item
						flex-basis: 100%
						width: 100%
					figure
							margin-bottom: 60px
				@media only screen and (max-width : 800px)
						flex-basis: 100%
						width: 100%
			.method_03
				padding-left: 80px
				flex-basis: calc(55% - 110px)
				width: calc(55% - 110px)
				background-color: white
				@media only screen and (max-width : 1200px)
					.grid-item
						flex-basis: 100%
						width: 100%
					article
							order: 1
					figure
							order: 0
							margin-bottom: 60px
				@media only screen and (max-width : 800px)
					padding-left: 30px
					flex-basis: 100%
					width: 100%
				@media only screen and (max-width : 480px)
					padding-left: 0
					padding-right: 0
				&:after
					content: ''
					position: absolute
					bottom: -140px
					right: calc( 50% - 100px )
					width: 200px
					height: 140px
					@include shape_puzzle(white)
					@media only screen and (max-width : 800px)
						bottom: -69px
						left: calc( 50% - 50px )
						width: 100px
						height: 70px
		.clients
			padding: 200px 0 100px 0
			@media only screen and (max-width : 800px)
				padding-top: 130px
			header
				text-align: center
				margin-bottom: 50px
				h3
					+title_huge
					margin-bottom: 15px
				p
					+text_big
					margin-bottom: 15px
			article
				ul
					justify-content: center
					li
						list-style: none
						padding: 10px
						border: 1px solid #ccc
						+box_hover
						margin: 10px
						img
							display: block
							width: 100%
							height: auto
